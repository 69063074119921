import React from "react";
import {observer} from "mobx-react";
import {Link, matchPath, withRouter} from "react-router-dom";
import {Breadcrumb} from 'antd';
import store from './BreadcrumbsStore';
const {Item} = Breadcrumb;

function isMatch(pathname, pattern) {
    return matchPath(pathname, {
        path: pattern,
        exact: false,
        strict: true
    });
}

function isExactMatch(pathname, pattern) {
    return matchPath(pathname, {
        path: pattern,
        exact: true,
        strict: true
    });
}

const bStyle = {margin: '16px 0',float:'left'};

const BreadcrumbsFromPath = observer(({pathname}) => {
    const p = pathname;
    let m;
    if (isMatch(p, "/t/report/boxes")) {
        return <Breadcrumb style={bStyle}>
            <Item>学生作业</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/report/box_setting/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/report/boxes">学生作业</Link></Item>
            <Item>{store.boxName}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/t/report/box/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/t/report/boxes">学生作业</Link></Item>
            <Item>{store.boxName}</Item>
        </Breadcrumb>
    }else if (isMatch(p, "/t/comment_library")) {
        return <Breadcrumb style={bStyle}>
            <Item>常用评语</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/t/report/comment_pdf/:reportId/:boxId") || isExactMatch(p, "/t/report/comment_view_pdf/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/t/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/t/report/comment_pdf/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }

    else if (m = isExactMatch(p, "/t/report/comment_image/:reportId/:boxId") || isExactMatch(p, "/t/report/comment_view_image/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/t/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/t/report/comment_image/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/t/report/comment_file/:reportId/:boxId") || isExactMatch(p, "/t/report/comment_view_file/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/t/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/t/report/comment_file/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/t/report/comment_raw_files/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/t/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/t/report/comment_raw_files/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/t/report/task_comment/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/t/report/box_step/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/t/report/task_comment/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/s/resource/manage")) {
        return <Breadcrumb style={bStyle}>
            <Item>资源管理</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_student")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/students">学生</Link></Item>
            <Item>导入学生</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_teacher")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/teachers">教师</Link></Item>
            <Item>导入教师</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/i_admin")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/admin">管理员</Link></Item>
            <Item>导入管理员</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/students")) {
        return <Breadcrumb style={bStyle}>
            <Item>学生</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/teachers")) {
        return <Breadcrumb style={bStyle}>
            <Item>教师</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/admin")) {
        return <Breadcrumb style={bStyle}>
            <Item>管理员</Item>
        </Breadcrumb>
    }else if (isMatch(p, "/a/name_list")) {
        return <Breadcrumb style={bStyle}>
            <Item>名单</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/a/name_list_info/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/a/name_list">名单</Link></Item>
            <Item>{store.nameListName}</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/s/report/boxes")) {
        return <Breadcrumb style={bStyle}>
            <Item>提交作业</Item>
        </Breadcrumb>
    }
    else if (isMatch(p, "/s/report/box/:id")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to="/s/report/boxes">提交作业</Link></Item>
            <Item>{store.boxName}</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/s/report/comment_view_pdf/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/s/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.nextReportId ? (<Link
                to={`/s/report/comment_view_pdf/${store.nextReportId}/${m.params.boxId}`}>下一份：{store.nextReportName}</Link>) : "下一份：没有了"}</Item>
        </Breadcrumb>
    }
    else if (m = isExactMatch(p, "/s/report/edit/:reportId/:boxId")) {
        return <Breadcrumb style={bStyle}>
            <Item><Link to={`/s/report/box/${m.params.boxId}`}>作业箱： {store.boxName}</Link></Item>
            <Item>{store.scoreItem}</Item>
        </Breadcrumb>
    }
    return <Breadcrumb style={bStyle}>
        <Item><Link to="/">主页</Link></Item>
    </Breadcrumb>
});

const Breadcrumbs = withRouter(({match, location, history}) => {
    return <BreadcrumbsFromPath pathname={location.pathname}/>
});
export default Breadcrumbs;