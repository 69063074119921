import React from "react";
import {Avatar, Col, Dropdown, Layout, Menu, Row} from 'antd';
import DataMenu from "../components/common/Menu/Menu";
import Breadcrumbs from "./BreadcrumbsFromPath";
import UserService from "../services/User";

require("./ReportLayout.css");
const {Header, Content, Footer} = Layout;

const userMenu = (
    <Menu>
        <Menu.Item key="logout">
            <a href="#/logout">
                退出
            </a>
        </Menu.Item>
    </Menu>
);


export default function ReportLayout({children}) {
    const name=window.localStorage.getItem("name");
    const logo=window.localStorage.getItem("logo");
    return (<Layout className="iframe">
        <Header style={{background: '#2B6AFA'}}>
            <Row>
                <Col span={20}>
                    <div  className="logo_content">
                        <div className='div_img'><img className='img' src={logo||window.config.logo}/></div>
                        <div className='content'>{name||window.config.name}</div>
                    </div>
                    <DataMenu
                        data={UserService.getCurrentUserMenu()}
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{lineHeight: '63px',background: '#2B6AFA'}}
                    />
                </Col>
                <Col span={4}>
                    <div className="header_right">
                        <Dropdown overlay={userMenu}>
                                <span>
                                    <a className="ant-dropdown-link" style={{fontWeight:"bold",color:"white"}}>
                                    {UserService.isAdmin()?"管理员  ":""}
                                        {UserService.isTeacher()?"教师  ":""}
                                        {UserService.isStudent()?"学生  ":""}
                                        {UserService.getCurrentUserShowName()}
                                    </a>
                                </span>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </Header>
        <div style={{padding: '0 20px',backgroundColor:"#ffffff"}}><Breadcrumbs/></div>
        {/*<Content style={{padding: '0 50px'}}>*/}
            <div style={{background: '#fff', padding: 24, minHeight: 600}}>
                {children}
            </div>
        {/*</Content>*/}
        {/*<Footer style={{textAlign: 'center'}}>OLAB ©2020 Created by Limsoluion</Footer>*/}
    </Layout>)
}
